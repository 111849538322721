.hidden {
  display: none;
}
#btn {
  position: absolute;
  top: 800px;
}
* {
  padding: 0;
  margin: 0;
}
.abs-100 {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.offcanvas {
  position: fixed;
}
.popup-mask {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s;
  opacity: 0;
  z-index: 1;
}
.popup-mask.active {
  opacity: 1;
}
.popup-wrapper {
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 80%;
  height: 300px;
  max-height: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.popup-wrapper.active .popup-content {
  opacity: 1;
  transform: translateY(0);
}
.popup-wrapper .popup-content {
  border-radius: 4px;
  background-color: #fff;
  opacity: 0;
  transform: translateY(2%);
  transition: all 0.3s;
  height: 100%;
}
.popup-wrapper .popup-content .close {
  width: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
