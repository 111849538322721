// out: ./popup.css, sourcemap: true, compress: false
.hidden{
  display: none;
}

#btn{
  position: absolute;
  top: 800px;
}

*{
  padding: 0;
  margin: 0;
}

.abs-100{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.offcanvas {
  position: fixed;
}

.popup-mask{
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  transition: opacity .3s;
  opacity: 0;
  z-index: 1;
  &.active{
    opacity: 1;
  }
}

.popup-wrapper{
  z-index: 2;
  .abs-100;
  width: 80%;
  height: 300px;
  max-height: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  &.active{
    .popup-content{
      opacity: 1;
      transform: translateY(0);
    }
  }
  .popup-content{
    border-radius: 4px;
    background-color: #fff;
    opacity: 0;
    transform: translateY(2%);
    transition: all .3s;
    height: 100%;
    .close{
      width: 20px;
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
  }
}
